<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main content-auto">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item, idx) in tabsList" :label="item.label" :name="item.name" :key="idx"/>
        </el-tabs>
        <div class="content-main-contain">
          <trajectory-playback v-if="activeName==='trajectory'"></trajectory-playback>
          <historical-video v-if="activeName==='video'"></historical-video>
          <local-playback v-if="activeName==='local'"></local-playback>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TrajectoryPlayback from './components/trajectory-playback'
  import HistoricalVideo from './components/historical-video'
  import LocalPlayback from './components/local-playback'

  export default {
    components: {
      TrajectoryPlayback,
      HistoricalVideo,
      LocalPlayback
    },
    data () {
      return {
        nav: [
          {name: '安全中心'},
          {name: '历史回放', path: '/safety-center/historical-playback'},
        ],
        activeName:  this.$route.query.activeName || 'trajectory',
        tabsList: [{
          label: '轨迹回放',
          name: 'trajectory'
        }, {
          label: '视频回放',
          name: 'video'
        }, {
          label: '本地回放',
          name: 'local'
        }
        ]
      }
    },
    methods: {
      handleClick () {
        this.$router.replace(`/safety-center/historical-playback?activeName=${this.activeName}`)
      }
    }
  }
</script>

<style lang='sass' scoped>
  .content-main
    padding: 0

    /deep/ .el-tabs__header
      border-bottom: 1px solid $grayShallow3
      padding-left: 12px
      margin-bottom: 0

    /deep/ .el-tabs__item
      color: #999
      font-size: $fontX
      height: 53px
      line-height: 53px

    /deep/ .el-tabs__item.is-active
      color: $-color-primary-2

    /deep/ .el-tabs__nav-wrap::after
      display: none

    &-contain
      height: calc(100% - 53px)
</style>
