<template>
  <div>
    <div class="bg-danger-style">
      <div style="width: 380px;text-align: right;display: inline-block;">
        <!-- 查询 -->
        <el-form :model="form" ref="form" :inline="true" :label-position="labelPosition" label-width="80px" class="demo-ruleForm">
          <el-form-item label="车牌" prop="deviceName">
            <el-select v-model="deviceName" placeholder="请选择车牌" filterable style="width: 270px">
              <el-option
                      v-for="(item, idx) in carList"
                      :key="idx"
                      :label="item.label"
                      :value="item.deviceName"
                      :disabled="item.deviceStatus==='无设备'">
              </el-option>
            </el-select>
            <!-- <el-input v-model="name" placeholder="车牌号/设备号/SIM卡号" clearable></el-input> -->
          </el-form-item>
          <el-form-item label="时间设置" prop="videoDate">
            <el-date-picker
              v-model="videoDate"
              type="date"
              value-format="yyyy-MM-dd"
              @change="dateInquire"
              :clearable ='false'
              placeholder="选择日期"
              style="width: 270px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="时间段" prop="videoDate">
            <el-time-picker
              is-range
              v-model="specificTime"
              range-separator="至"
              start-placeholder="开始时间"
              value-format="HH:mm:ss"
              end-placeholder="结束时间"
              @change="specificTimeInquire"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择时间范围"
              :clearable ='false'
              style="width: 270px">
            </el-time-picker>
          </el-form-item>
          <el-form-item label="存储位置" prop="list">
            <el-select v-model="store" placeholder="请选择" style="width: 270px">
              <el-option v-for="(item, idx) in form.list"
                         :key="idx"
                         :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="码流类型" prop="streamType">
            <el-select v-model="stream" placeholder="请选择" style="width: 270px">
              <el-option v-for="(item, idx) in form.streamType"
                         :key="idx"
                         :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="音视频" prop="typeAAV">
            <el-select v-model="res" placeholder="请选择" style="width: 270px">
              <el-option v-for="(item, idx) in form.typeAAV"
                         :key="idx"
                         :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="clickInquire()" :disabled="tableLoading">查 询</el-button>
          </el-form-item>
        </el-form>
        <!-- 查询 END-->
      </div>

      <!-- <div style="width: 100%; height: 500px">
      </div> -->

      <!-- 视频模块 -->
      <div class="main-video">
        <div class="video" style="height: 510px">
          <ayl-flv v-for="(item, idx) in allVideoUrl" :key="idx" :style="mainStyle" :url="item"></ayl-flv>
          <!-- <history-video v-for="(item, idx) in allVideoUrl" :key="idx" :style="mainStyle" :url="item" ref="rtmp"></history-video> -->
        </div> 
        <div class="video-operation">
        <!-- <div>
          <i class="iconfont curp" v-for="(item, idx) in iconList"
             :class="[item.name, screenNum===item.value?'active-icon': '']"
             :key="idx"
             @click="clickSwitch(item.value)"></i>
        </div> -->
      </div>
    </div>
  </div>
      <!-- 视频模块END -->


    <div class="bg-danger-style">
       <!-- 列表 -->
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="文件" name="file">
           <el-table :data="videoHistoryData" v-loading="tableLoading" height="550" style="margin-top: 10px;" :header-cell-style="{background:'rgba(250,250,250,1)'}">
            <el-table-column label="车牌号码" prop="plateNumber" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.plateNumber }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="通道号" width="80">
              <template slot-scope="scope">
                <span>{{ chnData(scope.row.channel) }}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="所属公司">
              <template slot-scope="scope">
                <span>{{ scope.row.carUnit }}</span>
              </template>
            </el-table-column>
            <el-table-column label="媒体类型" width="80">
              <template slot-scope="scope">
                <span>{{ storeData(scope.row.res) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="存储类型" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.storage }}</span>
              </template>
            </el-table-column>
            <el-table-column label="码流类型" prop="stream" ></el-table-column>
            <el-table-column label="大小(MB)" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.fileSize + 'MB'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="开始时间" width="180">
              <template slot-scope="scope">
                <span>{{ formatDate(scope.row.beginTime)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" width="180">
              <template slot-scope="scope">
                <span>{{ formatDate(scope.row.endTIme)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <div style="color:rgba(5, 117, 230, 1)" @click="playVideo(scope.row, scope.$index)" class="curp" >{{ scope.row.status?'重新播放':'播放'}}</div>
              </template>
            </el-table-column>
          </el-table>
          </el-tab-pane>
          <el-tab-pane label="报警列表" name="alarmList">
            <ayl-table :table="policeData" style="margin-top: 10px">
            </ayl-table>
          </el-tab-pane>
          <el-tab-pane label="下载状态" name="downloadStatus">
            <ayl-table :table="statusData" style="margin-top: 10px">
            </ayl-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 列表END -->
    </div>

  </div>
</template>

<script>
  import historyVideo from './videoPlay'
  const LineIcon = require('../../../../assets/images/map-icon (1).png')
  const offLineIcon = require('../../../../assets/images/map-icon (2).png')
  const onLineIcon = require('../../../../assets/images/map-icon (3).png')
  export default {
    name: 'videojs',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      historyVideo,
    },
    data () {
      const vm = this
      return {
        nav: [{name: "历史回放", path: ""}, {name: "视频回放", path: ""}],
        iconList: [{
          name: 'iconicon-test2',
          value: 1
        }, {
          name: 'iconicon-test3',
          value: 4
        }, {
          name: 'iconicon-test9',
          value: 9
        }],
        // 视频数据
        screenNum: 1,
        allVideoUrl: [],

        labelPosition: 'right',
        //车牌号列表
        carList: [],
        deviceName: this.$route.query.deviceName||'',
        videoDatas: null,

        activeName: 'file',
        plateNumber: null,

        // 车辆
        videoDate: new Date(),
        InquireYear: null,
        InquireMon: null,
        InquireDay: null,
        videoHistoryData: [],
        tableLoading: false,

        specificTime: ['00:00:00', '23:59:59'],
        start: 0,
        end: 86399,
        chn: 0,
        store: 0,
        name: null,
        amr1: 0,
        amr2: 0,
        stream: 0,
        res: 0,
        DevIDNO: null,

        form: {
          //通道号
          channel:[
            { label: '全部', value: 0},
            { label: '通道一', value: 1},
            { label: '通道二', value: 2},
            { label: '通道三', value: 3},
            { label: '通道四', value: 4},
            { label: '通道五', value: 5},
            { label: '通道六', value: 6},
          ],
          // 存储位置
          list: [
            {
              label: '主或者灾备存储器',
              value: 0
            },{
              label: '主存储器',
              value: 1
            },{
              label: '灾备存储器',
              value: 2
          }],
          // 码流类型
          streamType: [
            {
              label: '主或子码流',
              value: 0
            },{
              label: '主码流',
              value: 1
            },{
              label: '子码流',
              value: 2
            }
          ],
          // 音视频资源类型
          typeAAV: [
            {
              label: '音视频',
              value: 0
            },{
              label: '音频',
              value: 1
            },{
              label: '视频',
              value: 2
            },{
              label: '视频或者音视频',
              value: 3
            }
          ],
        },


        value: [],

        //policeData 报警列表
        policeData: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api.CarInfoByQueryCondition,
          // query 查询当前列表页数据的参数
          query: {
            plateColor: null,
            carStatus: null,
            startTime: '',
            endTime: '',
            queryContent: null,
          },
          columns: [
            {
            title: '序号',
            width: '100px',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carInfoVOIOV.plateNumber),
              ])
            }
          }, {
              title: '车牌号',
              width: '120px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.carInfoVOIOV.plateColor),
                ])
              }
          }, {
              title: '驾驶员姓名',
              width: '120px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.carInfoVOIOV.plateColor),
                ])
              }
          }, {
            title: '报警类型',
            width: '130px',
            render (h, ctx) {
              return h('span', [
                h('span', vm.$dayjs(ctx.row.carInfoVOIOV.serviceEndTime).format('YYYY-MM-DD')),
              ])
            }
          }, {
            title: '报警等级',
            width: '130px',
            render (h, ctx) {
              return h('span', [
                h('span', vm.$dayjs(ctx.row.carInfoVOIOV.serviceEndTime).format('YYYY-MM-DD')),
              ])
            }
          }, {
            title: '报警开始时间',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }, {
            title: '报警位置',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }, {
            title: '处理状态',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }, {
            title: '处理人',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }, {
            title: '附件',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }, {
            title: '操作',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }]
        },
        //下载状态table
        statusData: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api.CarInfoByQueryCondition,
          // query 查询当前列表页数据的参数
          query: {
            plateColor: null,
            carStatus: null,
            startTime: '',
            endTime: '',
            queryContent: null,
          },
          columns: [
            {
            title: '任务编号',
            width: '100px',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carInfoVOIOV.plateNumber),
              ])
            }
          }, {
              title: '车牌号',
              width: '120px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.carInfoVOIOV.plateColor),
                ])
              }
          }, {
              title: '通道号',
              width: '120px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.carInfoVOIOV.plateColor),
                ])
              }
          }, {
            title: '开始时间',
            width: '130px',
            render (h, ctx) {
              return h('span', [
                h('span', vm.$dayjs(ctx.row.carInfoVOIOV.serviceEndTime).format('YYYY-MM-DD')),
              ])
            }
          }, {
            title: '结束时间',
            width: '130px',
            render (h, ctx) {
              return h('span', [
                h('span', vm.$dayjs(ctx.row.carInfoVOIOV.serviceEndTime).format('YYYY-MM-DD')),
              ])
            }
          }, {
            title: '下载状态',
            width: '90px',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }, {
            title: '下载进度',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }]
        },
      }
    },
    methods:{
      // 处理历史视频返回的时间,为yyyy-MM-dd HH:mm:ss
      //开始时间
      initDate(year, mon, day, beg, end){
        let years = 2000 + year
        let mons = mon.toString()[1] ? mon: '0' + mon
        let days = day.toString()[1] ? day: '0' + day
        let endDays = day.toString()[1] ? day: '0' + day
        if( end > 86400 ){
          endDays = Number(endDays)  + 1
        }
        return years + '-' + mons + '-' + days + ' '+ this.initHours(beg)
      },
      //结束时间
      endDate(year, mon, day, beg, end){
        let years = 2000 + year
        let mons = mon.toString()[1] ? mon: '0' + mon
        let days = day.toString()[1] ? day: '0' + day
        let endDays = day.toString()[1] ? day: '0' + day
        if( end > 86400 ){
          endDays = Number(endDays)  + 1
        }
        return years + '-' + mons + '-' + endDays +' '+  this.initHours(end)
      },
      //时长
      durationData(end, beg){
        let duration = end - beg
        return Number(parseFloat((duration)/60).toFixed(1).slice(0,-1)) + '分钟' + duration%60 + '秒'
      },
      // 通道号
      chnData(chn){
        switch (chn) {
          case 1:
              return '通道一'
          case 2:
              return '通道二'
          case 3:
              return '通道三'
          case 4:
              return '通道四'
          case 5:
              return '通道五'
          case 6:
              return '通道六'
          default:
              return chn
        }
      },
      //文件位置
      locData(loc){
        switch (loc) {
          case 1:
            return '设备'
          case 2:
            return '存储服务器'
          case 4:
            return '下载服务器'
          default:
            return loc
        }
      },
      //存储位置
      storeData(store){
        switch (store) {
          case 0:
            return '主或者灾备存储器'
          case 1:
            return '主存储器'
          case 2:
            return '灾备存储器'
          default:
            return store
        }
      },
      //存储类型
      resData(res){
        switch (res){
          case 0:
            return '音视频'
          case 1:
            return '音频'
          case 2:
            return '视频'
          case 3:
            return '视频或者音视频'
        }
      },
      // 秒转时分秒
      initHours(date){
        let hours = parseInt(date / 3600) > 9 ? parseInt(date / 3600) === 24 ? '00': parseInt(date / 3600): '0' + parseInt(date / 3600)
        let tem = date % 3600
        let mins = parseInt(tem / 60) > 9 ? parseInt(tem / 60) : '0' + parseInt(tem / 60)
        let seconds = tem % 60 > 9 ? tem % 60 : '0' + tem % 60
        return hours + ':' + mins +  ':' + seconds
      },
    //根据条件查询车牌号和设备号  湘A25098  鄂G21270
    async clickDev (){
      this.videoHistoryData = []

    },
    // 请求车辆的历史视频记录
    async clickInquire(){
			if(this.InquireYear == null && this.InquireMon == null && this.InquireDay == null){
				this.$message.error('请选择车辆');
			}else {
       
        try {
          this.allVideoUrl = []
          this.videoHistoryData = []
          if(this.deviceName === null || this.deviceName === ''){
            this.$message.error('请选择车辆');
            return
          }
          this.tableLoading = true
          let videoData = await this.$api.getHistoryVideo({
            hvRequestVOIOV: {
              deviceName: this.deviceName,
              chn: 0,
              year: this.InquireYear + '',
              mon: this.InquireMon + '',
              day	: this.InquireDay + '',
              beg: this.start,
              end: this.end,
              arm1: 0,
              arm2: 0,
              res: this.res,
              stream: this.stream,
              store: this.store,
            }
          })
          this.videoHistoryData = videoData
          this.videoHistoryData.forEach(e=>{
            e.plateNumber = this.DevIDNO.plateNumber
          })
          this.videoHistoryData.forEach(e=>{
            e.status = false
          })
          
        // eslint-disable-next-line no-empty
        }catch (e) {
        }
        this.tableLoading = false
      }
      },
      // 时:分:秒转秒数
      timeToSec(time) {
        var s = '';
        var hour = time.split(':')[0];
        var min = time.split(':')[1];
        var sec = time.split(':')[2];
        s = Number(hour*3600) + Number(min*60) + Number(sec);
        return s;
      },
      // 拆分用户选择的年月日
      dateInquire (){
        if (!this.videoDate) return
        this.InquireYear = Number( this.videoDate.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$1') )
        this.InquireMon = Number( this.videoDate.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$2') )
        this.InquireDay = Number( this.videoDate.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$3') )
      },
      dateInquires (){
        let newDate = new Date()
        this.InquireYear = newDate.getFullYear()
        this.InquireMon = newDate.getMonth()+ 1
        this.InquireDay = newDate.getDate()
      },
      // 拆分用户选择的时分秒
      specificTimeInquire(){
        if (!this.specificTime){
          this.start = 0
          this.end = 86399
          return
        }
        this.start = this.timeToSec(this.specificTime[0])
        this.end = this.timeToSec(this.specificTime[1])
      },
      handleClick(tab, event) {
        //console.log(tab, event);
      },
      // 切换屏幕
      clickSwitch (v) {
        //if (this.allVideoUrl.length === 0) return
        if (v === this.screenNum) return
        this.allVideoUrl = []
        this.screenNum = v
       // this.playVideo()
      },
      // 点击播放
      async playVideo(item, idx){

        // let videoHistoryUrl = await this.$api.playHistoryVideo({
        //   deviceName: item.deviceName,
        //   channelList: Array.from({length: this.screenNum}, (v, i) => i + 1),
        //   begin: item.beginTime,
        //   end: item.endTIme
        // })
        this.allVideoUrl = []
        let videoHistoryUrl = await this.$api.playHistoryVideo({
          deviceName: item.deviceName,
          channelList: [item.channel],
          begin: item.beginTime,
          end: item.endTIme
        })
        

        setTimeout(() =>{
          this.allVideoUrl = Object.keys(videoHistoryUrl).map((k) => videoHistoryUrl[k])
          this.videoHistoryData.forEach(e=>{
            e.status = false
          })
          item.status = !item.status
          this.videoHistoryData.splice(idx, 1, item)
        },100)
        // let playingFlag = await this.$api.commonService_getVideoPlayingFlag({
        //     deviceName: item.deviceName,
        // })
        // if(playingFlag.playAble === false) {
        //     this.$message({
        //         message: `${playingFlag.userName}用户正在观看当前设备的视频，请稍后再试。`,
        //         type: 'warning'
        //     });
        //     return
        // } else {
        //     await this.$api.commonService_setVideoPlayingFlag({
        //         deviceName: item.deviceName,
        //     });
        //     let videoHistoryUrl = await this.$api.playHistoryVideo({
        //       deviceName: item.deviceName,
        //       channelList: [item.channel],
        //       begin: item.beginTime,
        //       end: item.endTIme
        //     })
            

        //     setTimeout(() =>{
        //       this.allVideoUrl = Object.keys(videoHistoryUrl).map((k) => videoHistoryUrl[k])
        //       this.videoHistoryData.forEach(e=>{
        //         e.status = false
        //       })
        //       item.status = !item.status
        //       this.videoHistoryData.splice(idx, 1, item)
        //     },100)
        // }
        
      },

      formatDate(row, column) {
        let date = new Date(parseInt(row));
        let Y = date.getFullYear() + '-';
        let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes()  < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      },
    },
    computed: {
      mainStyle () {
        if (this.screenNum === 4) return 'width: calc(100% / 2);height:calc(100% / 2)'
        if (this.screenNum === 9) return 'width: calc(100% / 3);height:calc(100% / 3)'
        return 'width: 100%;height:100%'
      }
    },
    async mounted () {
      //获取车辆信息
      let listCarData = await this.$api.getRealTimeCarInfoList({},)
      // 深度克隆，不直接引用后台的值
      this.carList = this.$_.cloneDeep(listCarData)
      // 调用递归, 获取所有公司下的车辆
      this.carList = this.$utils.getTreeAllData(this.carList)

      //日期初始化
      this.dateInquires()
    }
  }
</script>
<style lang='sass' scoped>
.font
  font-family: MicrosoftYaHei
/deep/ .el-form--inline
          .el-form-item
            margin-right: 30px
/deep/ .el-form-item__label
          color: #262626
/deep/ .video-js .vjs-big-play-button
          left: 50%
          top: 50%
/deep/ .el-table thead
        color: rgba(38, 38, 38, 1)
/deep/ .el-table th > .cell
        font-size: 14px
        color: rgba(38, 38, 38, 1)
/deep/ .el-date-editor .el-range-input
	margin-left: 2px
/deep/ .el-table .cell
        font-size: 14px
        color: rgba(102, 102, 102, 1)
/deep/ .el-form-item
        width: auto

/deep/ .el-date-editor .el-range-separator
    width: 6%

/deep/ .el-date-editor .el-range-input
    width: 36%

.video
    box-sizing: border-box
    width: calc(100% - 380px)
    height: 100%
    display: inline-block
    vertical-align: top
    background: #fff
    &_view
      width: 100%
      height: 450px
      line-height: 450px
      text-align: center
      background-color: #333
      color: #fff

.map-video-bottom
  width: 100%
  height: 30px
  line-height: 30px
  @extend %clearfix
  i
    color: $shallowBule
    /*transition: color .35s*/
    &:hover
      color: #3399FF
  .active
    color: #3399FF

.bg-danger-style
  background: white
  -webkit-box-shadow: 1px 0 6px 0 rgba(5, 117, 230, 0.1)
  box-shadow: 1px 0 6px 0 rgba(5, 117, 230, 0.1)
  border-radius: 2px
  padding: 24px 24px 0 24px
  //overflow-x: hidden
</style>

<style lang="sass">
.main-video
  width: calc(100% - 380px)
  display: inline

  .video
    height: calc(100% - 44px)
    background-color: #000

  .video-operation
    padding: 0 10px 0 20px
    height: 44px
    padding-left: 380px
    display: flex
    align-items: center
    justify-content: space-between

    i
      color: $grayShallow2
      margin-right: 10px

    .active-icon
      color: $-color-primary-2

    .operation-item
      display: inline-block
      width: 42px
      height: 20px
      border-radius: 10px
      font-size: 12px
      text-align: center
      line-height: 20px
      border: 1px solid $grayShallow2
      transition: .35s

      & + .operation-item
        margin-left: 10px

      &:hover
        border-color: $-color-primary-2
      @extend .active-icon
</style>
